import Masonry from 'masonry-layout';
import LazySizes from 'lazysizes';

require('slick-carousel');
require('slick-lightbox');

// Legacy code to initialize Slick & Masonry

LazySizes.init();

// responsive nav

let openNavButton = document.getElementById("openNav");
let closeNavButton = document.getElementById("closeNav");
let smNav = document.getElementById("sm-nav-container");

if (smNav) {
    smNav.style.display = "none";

    if (openNavButton) {
        openNavButton.addEventListener("click", function() {
            if (smNav) {
                smNav.style.display = "block";
                smNav.classList.toggle("onscreen");
            }
        });

    }

    if (closeNavButton) {
        closeNavButton.addEventListener("click", function() {
            if (smNav) {
                smNav.style.display = "none";
                smNav.classList.toggle("onscreen");
            }
        });
    }
}

const elems = document.getElementsByClassName('grid');
if (elems.length > 0) {
// masonry grid
    let grid = new Masonry('.grid', {
        itemSelector: '.grid-item',
        // use element for option
        columnWidth: '.grid-sizer',
    });
    grid.layout();

    $(document).on('lazyloaded', function () {
        grid.layout();
    });

// slick lightbox
    $(document).ready(function () {
        grid.layout();

        function hexToRgba(hex: string) {
            const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

            let hexColor = '#FFF';
            if (result) {
                hexColor = 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',0.98)';
            }

            return hexColor;
        }

        // @ts-ignore
        $('#slick-item').slickLightbox({
            itemSelector: '.grid-item a',
            infinite: true,
            lazy: false,
            imageMaxHeight: 1,
        }).on({
            'show.slickLightbox': function () {
                // Set the initial slide color
                // @ts-ignore
                const slickItem = $('#slick-item')[0].slickLightbox;
                const slide = slickItem.slick.slick('slickCurrentSlide');
                const color = $('.slick-image-link').eq(slide).data('dominant-color');
                $('.slick-lightbox')
                    .css('background-color', hexToRgba(color))
                    .css('transition', 'background-color 0.3s ease');
                // On before slide change
                // @ts-ignore
                slickItem.slick.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    // Change the slide color per slide
                    const color = $('.slick-image-link').eq(nextSlide).data('dominant-color');
                    $('.slick-lightbox')
                        .css('background-color', hexToRgba(color))
                        .css('transition', 'background-color 0.3s ease');
                });
            },
        });
    });
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    // noinspection TypeScriptValidateJSTypes
    module.hot.accept();
}
